import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <a id="psionic-prestige-classes"></a><h2>Psionic Prestige Classes
    </h2>
    <a id="war-mind"></a><h3>WAR MIND</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td></td>
        </tr>
      </tbody>
    </table>
    <a href="http://www.wizards.com/dnd/images/xph_gallery/80460.jpg" style={{
      "fontWeight": "bold"
    }}><img className="art-wide" src="pict.jpg" title="" alt="pict" style={{
        "border": "0px solid",
        "width": "73px",
        "height": "75px"
      }} /></a> <span style={{
      "fontWeight": "bold"
    }}>Hit Die:</span> d10.
    <a id="war-mind-requirements"></a><h6>Requirements</h6>
To qualify to become a war mind, a character must fulfill all the
following criteria.
    <p><span style={{
        "fontWeight": "bold"
      }}>Alignment:</span> Any
nonchaotic.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Base Attack Bonus:</span> +3.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Skills:</span> <a href="skillsAll.html#knowledge" style={{
        "color": "rgb(87, 158, 182)"
      }}>Knowledge</a>
(history) 2 ranks, <a href="psionicSkills.html#knowledge-psionics" style={{
        "color": "rgb(87, 158, 182)"
      }}>Knowledge (psionics)</a> 8 ranks.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Psionics:</span> Must have a
power point reserve of at least 1 power point.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Special:</span> Must have had
some instruction by another war mind.</p>
    <h6>Class Skills</h6>
The war mind&#8217;s class skills (and the key ability for each skill) are <a href="psionicSkills.html#autohypnosis" style={{
      "color": "rgb(87, 158, 182)"
    }}>Autohypnosis </a>(Wis), <a href="psionicSkills.html#concentration" style={{
      "color": "rgb(87, 158, 182)"
    }}>Concentration</a> (Con), <a href="skillsAll.html#intimidate" style={{
      "color": "rgb(87, 158, 182)"
    }}>Intimidate</a>
(Cha), <a href="skillsAll.html#knowledge" style={{
      "color": "rgb(87, 158, 182)"
    }}>Knowledge</a>
(history) (Int), <a href="psionicSkills.html#knowledge-psionics" style={{
      "color": "rgb(87, 158, 182)"
    }}>Knowledge (psionics)</a> (Int), and <a href="psionicSkills.html#psicraft" style={{
      "color": "rgb(87, 158, 182)"
    }}>Psicraft</a>
(Int).
    <p><span style={{
        "fontWeight": "bold"
      }}>Skill Points at Each Level:</span>
2 + Int modifier.</p>
    <a id="table-the-war-mind"></a><span style={{
      "fontWeight": "bold"
    }}>Table: The War
Mind&nbsp;&nbsp;&nbsp; 
    </span>
    <table style={{
      "width": "100%"
    }} border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr valign="bottom">
          <th style={{
            "width": "7%"
          }}>Level</th>
          <th style={{
            "width": "12%"
          }}>Base
Attack Bonus</th>
          <th style={{
            "width": "7%"
          }}>Fort
Save</th>
          <th style={{
            "width": "7%"
          }}>Ref
Save</th>
          <th style={{
            "width": "7%"
          }}>Will
Save</th>
          <th style={{
            "width": "24%"
          }}>Special</th>
          <th>Points/Day</th>
          <td style={{
            "verticalAlign": "bottom"
          }}><span style={{
              "fontWeight": "bold"
            }}>Powers Known</span>
          </td>
          <td style={{
            "verticalAlign": "bottom"
          }}><span style={{
              "fontWeight": "bold"
            }}>Maximum Power Level Known</span>
          </td>
        </tr>
        <tr className="odd-row">
          <td>1st</td>
          <td>+1</td>
          <td>+2</td>
          <td>+2</td>
          <td>+0</td>
          <td>Chain of personal superiority +2
          </td>
          <td>2
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>1
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>1st
          </td>
        </tr>
        <tr>
          <td>2nd</td>
          <td>+2</td>
          <td>+3</td>
          <td>+3</td>
          <td>+0</td>
          <td>Chain of defensive posture +2
          </td>
          <td>5
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>2
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>1st
          </td>
        </tr>
        <tr className="odd-row">
          <td>3rd</td>
          <td>+3</td>
          <td>+3</td>
          <td>+3</td>
          <td>+1</td>
          <td>Enduring body (DR 1/-)
          </td>
          <td>9
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>2
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>1st
          </td>
        </tr>
        <tr>
          <td>4th</td>
          <td>+4</td>
          <td>+4</td>
          <td>+4</td>
          <td>+1</td>
          <td>-
          </td>
          <td>14
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>3
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>2nd
          </td>
        </tr>
        <tr className="odd-row">
          <td>5th</td>
          <td>+5</td>
          <td>+4</td>
          <td>+4</td>
          <td>+1</td>
          <td>Sweeping strike
          </td>
          <td>20
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>3
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>2nd
          </td>
        </tr>
        <tr>
          <td>6th</td>
          <td>+6</td>
          <td>+5</td>
          <td>+5</td>
          <td>+2</td>
          <td>Enduring body (DR 2/-)
          </td>
          <td>28
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>4
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>3rd
          </td>
        </tr>
        <tr className="odd-row">
          <td>7th</td>
          <td>+7</td>
          <td>+5</td>
          <td>+5</td>
          <td>+2</td>
          <td>Chain of personal superiority +4
          </td>
          <td>37
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>4
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>3rd
          </td>
        </tr>
        <tr>
          <td>8th</td>
          <td>+8</td>
          <td>+6</td>
          <td>+6</td>
          <td>+2</td>
          <td>Chain of defensive posture +4
          </td>
          <td>47
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>5
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>4th
          </td>
        </tr>
        <tr className="odd-row">
          <td>9th</td>
          <td>+9</td>
          <td>+6</td>
          <td>+6</td>
          <td>+3</td>
          <td>Enduring body (DR 3/-)
          </td>
          <td>58
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>5
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>4th
          </td>
        </tr>
        <tr>
          <td className="last-row">10th</td>
          <td className="last-row">+10</td>
          <td className="last-row">+7</td>
          <td className="last-row">+7</td>
          <td className="last-row">+3</td>
          <td className="last-row">Chain of overwhelming force
          </td>
          <td className="last-row">70
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>6
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>5th
          </td>
        </tr>
      </tbody>
    </table>
    <a id="war-mind-class-features"></a><h6>Class Features</h6>
All the following are class features of the war mind prestige class.
    <p><span style={{
        "fontWeight": "bold"
      }}>Weapon and Armor Proficiency:</span>
War minds gain no proficiency with any weapon or armor.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Power Points/Day:</span> A
war mind can manifest powers. His ability to manifest powers is limited
by the power points he has available. His base daily allotment of power
points is given on Table: The War Mind. In addition, he receives bonus
power points per day if he has a high Wisdom score. His race may also
provide bonus power points per day, as may certain feats and items. If
a war mind has power points from a different class, those points are
pooled together and usable to manifest powers from either class. Bonus
power points from having a high ability score can be gained only for
the character&#8217;s highest psionic class.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Powers Known:</span> A war
mind chooses his powers from the <a href="PsionicWarriorList.html" style={{
        "color": "rgb(87, 158, 182)"
      }}>psychic warrior power list</a>. At
1st
level, a war mind knows one psychic warrior power of your choice. At
every even-numbered level higher than 1st, he learns one new power. A
war mind can manifest any power that has a power point cost equal to or
lower than his manifester level. The total number of powers a war mind
can manifest per day is limited only by his daily power points.
A war mind simply knows his powers; they are ingrained in his mind. He
does not need to prepare them (in the way that some spellcasters
prepare their spells), though he must get a good night&#8217;s sleep each day
to regain all his spent power points.
The Difficulty Class for saving throws against war mind powers is 10 +
the power&#8217;s level + the war mind&#8217;s Wisdom modifier.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Maximum Power Level Known:</span>
A war mind gains the ability to learn one 1st-level power when he takes
his first level in the prestige class. As he attains each even-numbered
level beyond 2nd, a war mind gains the ability to master more complex
powers.
To learn or manifest a power, a war mind must have a Wisdom score of at
least 10 + the power&#8217;s level.</p>
    <a id="war-mind-chain-of-personal-superiority"></a><p><span style={{
        "fontWeight": "bold"
      }}>Chain of Personal Superiority
(Ex):</span> At 1st level, a war mind learns the first principle of
warfare for the individual combatant: the ability to both deal
punishment and take it. Calling upon inner reserves of knowledge and
dedication, a war mind can provide himself with a +2 insight bonus to
Strength and Constitution for up to 1 minute. A war mind can use this
power three times per day. Activating this power is a free action. At
7th level, the insight bonus to Strength and Constitution improves to
+4.</p>
    <a id="cahin-of-defensive-posture"></a><p><span style={{
        "fontWeight": "bold"
      }}>Chain of Defensive Posture
(Ex):</span> At 2nd level, a war mind learns the second principle of
warfare for the individual combatant: the ability to avoid the enemy&#8217;s
counterattacks if that enemy is not immediately overwhelmed. Calling
upon inner reserves of knowledge and dedication, a war mind can provide
himself with a +2 insight bonus to Armor Class for up to 1 minute. A
war mind can use this power three times per day. Activating this power
is a free action. At 8th level, the insight bonus to Armor Class
improves to +4.</p>
    <a id="war-mind-enduring-body"></a><p><span style={{
        "fontWeight": "bold"
      }}>Enduring Body (Ex):</span> At
3rd level, a war mind learns the third principle of warfare for the
individual combatant: to unleash in oneself the spirit of the enduring
body. The spirit of the ideal body transforms a war mind, granting him
damage reduction 1/-. At 6th level, his damage reduction improves to
2/-. At 9th level, his damage reduction improves to 3/-.</p>
    <a id="war-mind-sweeping-strike"></a><p><span style={{
        "fontWeight": "bold"
      }}>Sweeping Strike (Ex):</span>
At 5th level, a war mind gains the ability to make great, sweeping
swings with a melee weapon. On each melee attack a war mind makes, he
can choose squares he threatens that are adjacent to each other, and
his attacks apply to creatures in those two squares equally. A war mind
can use this ability on any attack, even an attack of opportunity or a <a href="featsAll.html#cleave" style={{
        "color": "rgb(87, 158, 182)"
      }}>cleave</a>
attempt.
A war mind cannot use this ability if he has moved more than 10 feet
since the end of his last turn. If a war mind drops one or both of his
foes with a sweeping strike, he can attempt a cleave normally; however,
he makes only one cleave attempt per sweeping strike, even if he drops
more than one foe.</p>
    <a id="war-mind-chain-of-pverwhelming-force"></a><p><span style={{
        "fontWeight": "bold"
      }}>Chain of Overwhelming Force
(Su):</span> At 10th level, a war mind learns the fourth principle of
warfare for the individual combatant: to discover the underlying
violence of the world and deliver it in a perfectly executed attack.
The war mind taps into this underlying energy and apply it to a single
attack, dealing an extra 10d6 points of damage. A war mind can use this
power once per day. Activating this power is a free action. If the
attack misses, the power is wasted.</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      